import Simple from '@/components/task-templates/Simple'

export default [
  {
    component: Simple,
    name: 'true и false',
    text: 'Ничего не дано. Вывести true и false через пробел',
    note: 'Вывести true и false как логические значения',
    input: 'ничего',
    output: 'true и false через пробел',
    examples: [
      {
        input: '',
        output: 'true false'
      }
    ]
  },
  {
    component: Simple,
    name: 'Положительное',
    text: 'Дано целое число \\(a\\). Верно ли, что оно положительное?',
    note: 'В этой задаче, как и во всех остальных в этой группе, не используется if. Вместо этого в прямо в выводе пишем логическое выражение. Таким образом будет выводиться true или false',
    input: '\\(a\\) - целое',
    output: 'true или false',
    examples: [
      {
        input: '5',
        output: 'true'
      },
      {
        input: '0',
        output: 'false'
      },
      {
        input: '-4',
        output: 'false'
      }
    ]
  },
  {
    component: Simple,
    name: 'Чётное',
    text: 'Дано целое число \\(a\\). Верно ли, что оно чётное?',
    note: null,
    input: '\\(a\\) - целое неотрицательное',
    output: 'true или false',
    examples: [
      {
        input: '5',
        output: 'false'
      },
      {
        input: '0',
        output: 'true'
      },
      {
        input: '6',
        output: 'true'
      }
    ]
  },
  {
    component: Simple,
    name: 'Делится или нет',
    text: 'Даны числа \\(a\\) и \\(b\\). Верно ли, что \\(a\\) не делится на \\(b\\)?',
    note: null,
    input: '\\(a\\) - целое неотрицательное, \\(b\\) - целое положительное',
    output: 'true или false',
    examples: [
      {
        input: '5, 3',
        output: 'true'
      },
      {
        input: '0, 2',
        output: 'false'
      },
      {
        input: '15, 3',
        output: 'false'
      }
    ]
  },
  {
    component: Simple,
    name: 'Оканчивается на 4 или 7',
    text: 'Дано число \\(a\\). Верно ли, что оно оканчивается на цифру 4 или 7?',
    note: null,
    input: '\\(a\\) - целое неотрицательное',
    output: 'true или false',
    examples: [
      {
        input: '4',
        output: 'true'
      },
      {
        input: '57',
        output: 'true'
      },
      {
        input: '75',
        output: 'false'
      }
    ]
  },
  {
    component: Simple,
    name: 'Чётное не с 4 на конце',
    text: 'Дано число \\(a\\). Верно ли, что оно чётное, которое оканчивается не на 4?',
    note: null,
    input: '\\(a\\) - целое неотрицательное',
    output: 'true или false',
    examples: [
      {
        input: '14',
        output: 'false'
      },
      {
        input: '57',
        output: 'false'
      },
      {
        input: '42',
        output: 'true'
      }
    ]
  },
  {
    component: Simple,
    name: 'Оба положительные',
    text: 'Даны два числа \\(a\\) и \\(b\\). Верно ли, что они оба положительные?',
    note: null,
    input: '\\(a\\), \\(b\\) - целые',
    output: 'true или false',
    examples: [
      {
        input: '5, 6',
        output: 'true'
      },
      {
        input: '0, 3',
        output: 'false'
      },
      {
        input: '-1, -2',
        output: 'false'
      }
    ]
  },
  {
    component: Simple,
    name: 'Есть положительное',
    text: 'Даны два числа \\(a\\) и \\(b\\). Верно ли, что хотя бы одно из них положительное?',
    note: null,
    input: '\\(a\\), \\(b\\) - целые',
    output: 'true или false',
    examples: [
      {
        input: '5, 6',
        output: 'true'
      },
      {
        input: '0, 3',
        output: 'true'
      },
      {
        input: '-1, -2',
        output: 'false'
      }
    ]
  },
  {
    component: Simple,
    name: 'Разные знаки',
    text: 'Даны два целых числа \\(a\\) и \\(b\\). Верно ли, что одно из них положительное, а другое отрицательное?',
    note: 'Можно воспользоваться свойствами умножения. Произведение чисел с разными знаками всегда отрицательное',
    input: '\\(a\\), \\(b\\) - целые',
    output: 'true или false',
    examples: [
      {
        input: '5, -6',
        output: 'true'
      },
      {
        input: '-5, 6',
        output: 'true'
      },
      {
        input: '5, 6',
        output: 'false'
      },
      {
        input: '-5, -6',
        output: 'false'
      },
      {
        input: '5, 0',
        output: 'false'
      }
    ]
  },
  {
    component: Simple,
    name: 'Цепочка сравнений',
    text: 'Даны три целых числа \\(a\\), \\(b\\) и \\(c\\). Верно ли выражение \\(a < b < c\\)?',
    note: null,
    input: '\\(a\\), \\(b\\), \\(c\\) - целые',
    output: 'true или false',
    examples: [
      {
        input: '5, 10, 11',
        output: 'true'
      },
      {
        input: '5, 11, 10',
        output: 'false'
      },
      {
        input: '7, 7, 7',
        output: 'false'
      }
    ]
  },
  {
    component: Simple,
    name: 'Среднее число',
    text: 'Даны три целых числа \\(a\\), \\(b\\) и \\(c\\). Верно ли, что \\(b\\) не большее и не меньшее из чисел?',
    note: null,
    input: '\\(a\\), \\(b\\), \\(c\\) - целые',
    output: 'true или false',
    examples: [
      {
        input: '5, 10, 11',
        output: 'true'
      },
      {
        input: '11, 10, 5',
        output: 'true'
      },
      {
        input: '11, 10, 10',
        output: 'false'
      }
    ]
  },
  {
    component: Simple,
    name: 'Чистая победа',
    text: 'Вася и Петя поспорили, кто из них решит больше задач за одинаковое время. Вам даны два числа \\(a\\) и \\(b\\) - количество задач, решенных Васей и Петей соответственно. Выведите, чистой ли была победа. Победа считается чистой, если победитель решил как минимум на две задачи больше, чем проигравший',
    note: 'Допускается не больше двух сравнений',
    input: '\\(a\\), \\(b\\) - целые неотрицательные',
    output: 'true или false',
    examples: [
      {
        input: '20, 15',
        output: 'true'
      },
      {
        input: '20, 22',
        output: 'true'
      },
      {
        input: '10, 11',
        output: 'false'
      },
      {
        input: '10, 10',
        output: 'false'
      }
    ]
  },
  {
    component: Simple,
    name: 'Двузначное',
    text: 'Дано целое число \\(a\\). Верно ли, что оно двузначное?',
    note: null,
    input: '\\(a\\) - целое положительное',
    output: 'true или false',
    examples: [
      {
        input: '50',
        output: 'true'
      },
      {
        input: '9',
        output: 'false'
      },
      {
        input: '100',
        output: 'false'
      }
    ]
  },
  {
    component: Simple,
    name: 'Нечётное трехзначное или четное двузначное',
    text: 'Дано число \\(a\\). Верно ли, что оно нечётное трехзначное или четное двузначное?',
    note: null,
    input: '\\(a\\) - целое положительное',
    output: 'true или false',
    examples: [
      {
        input: '8',
        output: 'false'
      },
      {
        input: '9',
        output: 'false'
      },
      {
        input: '51',
        output: 'false'
      },
      {
        input: '52',
        output: 'true'
      },
      {
        input: '100',
        output: 'false'
      },
      {
        input: '101',
        output: 'true'
      },
      {
        input: '9998',
        output: 'false'
      },
      {
        input: '9999',
        output: 'false'
      }
    ]
  },
  {
    component: Simple,
    name: 'Палиндром',
    text: 'Дано четырёхзначное число \\(a\\). Верно ли, что оно одинаково читается справа налево и слева направо?',
    note: 'Для решения задачи лучше всего создать 4 переменные (например, a1, a2, a3, a4), в которые занести соответствующие цифры. Для получения цифр использовать целочисленное деление (или с округлением вниз) и остаток от деления. Для получения второй и третьей цифры нужно по два действия.',
    input: '\\(a\\) - целое положительное четырёхзначное',
    output: 'true или false',
    examples: [
      {
        input: '1001',
        output: 'true'
      },
      {
        input: '9999',
        output: 'true'
      },
      {
        input: '1233',
        output: 'false'
      }
    ]
  },
  {
    component: Simple,
    name: 'Существует ли треугольник',
    text: 'Даны числа \\(a\\), \\(b\\) и \\(c\\). Верно ли, что существует треугольник со сторонами \\(a\\), \\(b\\) и \\(c\\)?',
    note: 'Не существует треугольника, у которого одна сторона больше либо равна сумме двух других сторон.',
    input: '\\(a\\), \\(b\\) и \\(c\\) - целые положительные',
    output: 'true или false',
    examples: [
      {
        input: '1, 1, 1',
        output: 'true'
      },
      {
        input: '3, 4, 5',
        output: 'true'
      },
      {
        input: '1, 2, 3',
        output: 'false'
      },
      {
        input: '7, 3, 3',
        output: 'false'
      }
    ]
  },
  {
    component: Simple,
    name: 'Високосный год',
    text: 'Дано число \\(n\\) - номер года. Верно ли, что этот год високосный?',
    note: 'Високосный год - это год, номер которого делится на 4, кроме случаев начала столетия. Год начала столетия (например, 1700, 1800, 1900, 2000) является високосным, только если делится на 400',
    input: '\\(a\\) - целое положительное',
    output: 'true или false',
    examples: [
      {
        input: '2020',
        output: 'true'
      },
      {
        input: '1996',
        output: 'true'
      },
      {
        input: '1990',
        output: 'false'
      },
      {
        input: '2000',
        output: 'true'
      },
      {
        input: '1900',
        output: 'false'
      }
    ]
  }
]
